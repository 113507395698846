#movement_info{
    margin:16px;
}
::-webkit-scrollbar{
    width:6px
}
::-webkit-scrollbar-track {
    background: #1f1f1f;
  }
::-webkit-scrollbar-thumb {
    background: #AA0000;
    border-radius: 50px;    
}
::-webkit-scrollbar-thumb:hover {
    background: #F40000;
  }
.ControlSections-section_container {
  
}
.ControlSections-section{
    margin-top: 16px;
}