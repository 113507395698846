.aa-input-search{
    background-color: #404040;
    border-radius: 4px;
    color:#FFFFFF
}
.aa-dropdown-menus{
    z-index: 10;
    position: absolute;
    width:100%;
    max-height: 50vh;
    overflow-y: auto;
    transition: all .5s ease-out;
    .aa-dropdown-menu{
        width:100%;
        height:100%;
        background-color: #404040;
        color:#FFFFFF;
        .aa-suggestions-category{

        }
        .aa-suggestions{
            .aa-suggestion{
                padding:0;
                &:hover{
                    color:#f40000;
                    .hit{
                        .hit-description{
                            small{
                                color:rgba(255,255,255,1);
                                transition: all .3s ease-out;
                            }
                        }
                    }
                }
                .hit{
                    padding: 8px;
                    border-top: solid 1px rgba(255,255,255,.5);
                    transition: all .3s ease-out;
                    .hit-description{
                        small{
                            color:rgba(255,255,255,.5);
                            transition: all .3s ease-out;

                        }
                    }
                }
                .hidden{
                    display:none;
                }
            }
        
        }
    }
}